export const BUILDING_GET_ALL = '/building/list'
export const BUILDING_CREATE = '/building/create'
export const BUILDING_BLOCK = '/building/block'
export const BUILDING_DELETE = '/building/delete/'
export const BUILDING_DETAIL = '/building/detail/'
export const BUILDING_EDIT = '/building/edit'
export const BUILDING_TYPE = '/building/type/list'
export const BUILDING_BUSINESS_CREATE = '/building/business/create'
export const BUILDING_RULE_LIST = '/building/rule/list/'
export const BUILDING_RULE_CREATE = '/building/rule/create'
export const BUILDING_RULE_EDIT = '/building/rule/edit'
export const BUILDING_RULE_DELETE = '/building/rule/delete/'
export const BUILDING_RULE_DETAIL = '/building/rule/detail/'
export const BUILDING_ASSIGN_MANAGER = '/building/assign/manager'